<template>
  <b-card>
    <b-row>
      <b-col md="12">
        <h4>Gestión de Beneficios</h4>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col md="7">
        <b-form @submit.prevent="onSubmit">
          <b-input-group>
            <b-input-group-append>
              <b-button
                v-if="hasContent"
                id="btnClearSearchBeneficio"
                v-b-tooltip.hover="'Limpiar filtro'"
                class="closeStyle"
                size="sm"
                @click="()=>clearSearchBeneficio()"
              >

                <font-awesome-icon icon="fa-solid fa-eraser" />
              </b-button>
            </b-input-group-append>
            <b-form-input
              id="txtFiltroNombreBeneficio"
              ref="inputFilter"
              v-model="filtro"
              :disabled="isBusyBuscando"
              autocomplete="off"
              placeholder=" Búsqueda por ( Nombre beneficio)"
            />
            <!--<div class="align-self-end ml-auto">-->
            <b-input-group-prepend>
              <b-button
                id="btnSearchBeneficio"
                v-b-tooltip.hover
                size="sm"
                title="Buscar beneficio"
                variant="primary"
                squared
                :disabled="isBusyBuscando"
                @click="buscarBeneficio()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
              <b-form-select
                id="selectBeneficioEstado"
                v-model="beneficioFiltroDto.filtroEstado"
                v-b-tooltip.hover="'Seleccione el estado'"
                :options="optionsEstado"
                rounded
                value-field="value"
                text-field="text"
                :disabled="isBusyBuscando"
              />

            </b-input-group-prepend>
          </b-input-group>
        </b-form>
      </b-col>
      <b-col
        md="5"
        class="text-right"
      >
        <b-button
          v-if="permisosIns.includes(nameModulo)"
          id="btnAddBeneficio"
          v-b-tooltip.hover
          squared
          size="sm"
          style="float: right;"
          title="Nuevo beneficio"
          variant="success"
          :disabled="isBusyBuscando"
          @click="()=>addBeneficio()"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col md="12">
        <b-table
          id="tblBeneficio"
          :items="tableDataBeneficio"
          :busy="isBusyBuscando"
          :fields="fieldsBeneficio"
          outlined
          show-empty
          small
          hover
          sticky-header="70vh"
          :no-border-collapse="true"
          empty-text="No se encontraron resultados para esta búsqueda"
          @row-dblclicked="dobleClik"
        >
          <template v-slot:table-busy>
            <div class="text-center">
              <b-spinner />
            </div>
          </template>
          <template #cell(esActivo)="row">
            <small>
              {{ row.item.esActivo ? 'Activo': 'Inactivo' }}
            </small>
          </template>
          <template #cell(vigenciaDesde)="row">
            <small>
              {{ formatDateTable(row.item.vigenciaDesde, 'DD/MM/YYYY') }}
            </small>
          </template>
          <template #cell(vigenciaHasta)="row">
            <small>
              {{ formatDateTable(row.item.vigenciaHasta, 'DD/MM/YYYY') }}
            </small>
          </template>
          <template #cell(actions)="row">
            <div
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosVer.includes(nameModulo)"
                v-b-tooltip.hover.righttop="'Ver Beneficio'"
                size="sm"
                @click="viewBeneficio(row.item, row.index, $event.target)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="permisosUpd.includes(nameModulo)"
                v-b-tooltip.hover.righttop="'Editar Beneficio'"
                size="sm"
                @click="editBeneficio(row.item, row.index, $event.target)"
              >
                <font-awesome-icon icon="fa-regular fa-pen-to-square" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <div class="align-self-end ml-auto">
        <b-col md="12">
          <b-input-group>

            <b-input-group-append>
              <!--v-b-tooltip.hover="'Seleccione la cantidad de resultados por página'"-->
              <b-form-select
                v-model="pageBeneficio.size"
                :clearable="false"
                title="Seleccione la cantidad de resultados por página"
                label="title"
                :options="optionsPaginationSize"
                size="sm"
                :disabled="isBusyBuscando"
                @change="buscarBeneficio()"
              />
            </b-input-group-append>

            <b-pagination
              id="pgnbENEFICIO"
              v-model="currentPage"
              :total-rows="pageTotalElements"
              :per-page="pageSize"
              :disabled="isBusyBuscando"
              @change="changePaginate"
            />
          </b-input-group>
        </b-col>
      </div>
    </b-row>
  </b-card>
</template>
<script>
// import BCardCode from '@core/components/b-card-code'
import { mapGetters } from 'vuex'
import { beneficiosResource } from '@/services/beneficiosResource'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BCardCode,
  },
  data() {
    return {
      useLocalStorageLastSearch: true,
      cargando: false,
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      filtro: null,
      beneficioFiltroDto: {
        pageNumber: null,
        pageSize: null,
        filtroNombre: null,
        sort: null,
        filtroEstado: null,
        order: null,
      },
      pageBeneficio: {
        page: 0,
        size: 7,
      },
      nameModulo: 'CONFIGURACION_BENEFICIO',
      isBusyBuscando: false,
      isActivando: false,
      tableDataBeneficio: [],
      optionsPaginationSize: [5, 7, 10, 15, 20, 25, 30],
      optionsEstado: [
        { value: null, text: 'Todos' },
        { value: true, text: 'Activo' },
        { value: false, text: 'Inactivo' },
      ],
      fieldsBeneficio: [
        {
          key: 'nombre', label: 'Nombre', thStyle: { width: '35%' },
        },
        {
          key: 'esActivo', label: 'estado',
        },
        {
          key: 'vigenciaDesde', label: 'desde',
        },
        {
          key: 'vigenciaHasta', label: 'hasta',
        },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },

  computed: {
    hasContent() {
      return (this.filtro != null && this.filtro.length > 0)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.veryAndSetLastPath()
    if (this.useLocalStorageLastSearch) { this.applyLastSearch() }
    this.getBeneficioByFilter()
  },
  methods: {
    veryAndSetLastPath() {
      const lastPath = localStorage.getItem('lastPath')
      if (lastPath !== null && !lastPath.includes('beneficio')) {
        localStorage.setItem('config_beneficio_lastSearch', '')
      }
      localStorage.setItem('lastPath', this.$route.path)
    },
    applyLastSearch() {
      if (localStorage.getItem('config_beneficio_lastSearch')) {
        this.filtro = localStorage.getItem('config_beneficio_lastSearch')
      } else {
        this.filtro = null
      }
      if (localStorage.getItem('config_beneficio_page_size_lastset')) {
        this.pageBeneficio.size = localStorage.getItem('config_beneficio_page_size_lastset')
      } else {
        this.pageBeneficio.size = 7
      }
    },
    dobleClik(record) {
      this.$router.push(`/beneficio/view/${record.id}`)
    },
    onSubmit() {
      if (!this.isBusyBuscando) {
        this.buscarBeneficio()
      }
    },
    clearSearchBeneficio() {
      this.filtro = ''
      // this.beneficioFiltroDto.filtroEstado = null
      this.buscarBeneficio()
    },
    viewBeneficio(item) {
      this.$router.push(`/beneficio/view/${item.id}`)
    },
    editBeneficio(item) {
      this.$router.push(`/beneficio/edit/${item.id}`)
    },
    addBeneficio() {
      this.$router.push('/beneficio/new')
    },
    buscarBeneficio() {
      if (this.useLocalStorageLastSearch) {
        if (this.filtro) {
          localStorage.setItem('config_beneficio_lastSearch', this.filtro)
        } else {
          localStorage.setItem('config_beneficio_lastSearch', '')
        }
        localStorage.setItem('config_beneficio_page_size_lastset', this.pageBeneficio.size)
      }
      this.currentPage = 1
      this.pageBeneficio.page = 0
      this.getBeneficioByFilter()
    },
    getBeneficioByFilter() {
      this.isBusyBuscando = true
      this.beneficioFiltroDto.pageNumber = this.pageBeneficio.page
      this.beneficioFiltroDto.pageSize = this.pageBeneficio.size
      this.beneficioFiltroDto.filtroNombre = this.filtro
      this.beneficioFiltroDto.sort = 'nombre'
      this.beneficioFiltroDto.order = 'ASC'
      beneficiosResource().findBeneficioByFilter(this.beneficioFiltroDto)
        .then(resultFindBeneficioByFilter => {
          this.tableDataBeneficio = resultFindBeneficioByFilter.content
          this.pageTotalElements = resultFindBeneficioByFilter.totalElements
          this.pageNumber = resultFindBeneficioByFilter.number
          this.pageSize = resultFindBeneficioByFilter.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataBeneficio = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    changePaginate(item) {
      this.pageBeneficio.page = item - 1
      this.isBusyBuscando = true
      this.getBeneficioByFilter()
    },
  },
}
</script>
<style>
.closeStyle {
  border-color: #cad1d7;
}
.text-red {
  color: red;
}
</style>
