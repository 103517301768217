import axios from 'axios'
import {
  getBeneficioById,
  postGetBeneficioFilter,
  postSaveBeneficio,
  putUpdateBeneficio,
} from '@/utils/paths'

const pathGetBeneficioById = getBeneficioById
const pathPostGetBeneficioFilter = postGetBeneficioFilter
const pathPostSaveBeneficio = postSaveBeneficio
const pathPutUpdateBeneficio = putUpdateBeneficio

export function beneficiosResource() {
  function findBeneficioById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetBeneficioById + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findBeneficioByFilter(beneficioFiltroDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostGetBeneficioFilter, beneficioFiltroDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveBeneficio(beneficioDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveBeneficio, beneficioDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateBeneficio(id, beneficioDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateBeneficio + id, beneficioDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findBeneficioById,
    findBeneficioByFilter,
    saveBeneficio,
    updateBeneficio,
  }
}
